import React, { useContext, useState, useEffect } from 'react'
import Grid from "@mui/material/Grid";
import * as S from './styles'
import Header from '../../containers/header'
import Dialog from '@mui/material/Dialog'
import {
  web3Enable,
  web3Accounts,
} from '@polkadot/extension-dapp'
import { formatBalance } from '@polkadot/util';
//import { Abi, ContractPromise } from '@polkadot/api-contract'
import type { InjectedAccountWithMeta } from '@polkadot/extension-inject/types'
import { ApiPromise } from '@polkadot/api'
import { ContractPromise } from '@polkadot/api-contract'
import { ApiContext } from '../../context/ApiContext'
import ABI from '../../artifacts/lunesnft.json'
import { Keyring } from '@polkadot/api';
// Create a keyring instance
const keyring = new Keyring({ type: 'sr25519' });
//Metamask
import { useSDK } from '@metamask/sdk-react';
import { Alert, Box, Button, CircularProgress, TextField } from '@mui/material'

const CONTRACT_ADDRESS: string = process.env.REACT_APP_CONTRACT_ADDRESS || '5CJhq4Nbt47V2EWNxfmFF6ejbVSFzQHTFmEhYfR7narNg8hq'
var TOKEN_ID = "1"
const PASS: string = process.env.REACT_APP_PASS || '1234'
const DECIMAIS_BNB = 1000000000000000000
const SEED_AUX: string = process.env.REACT_APP_SEED || 'bottom drive obey lake curtain smoke basket hold race lonely fit walk//Alice'
const BNB_TEST = {
  chainId: '0x61', //PRD 0x38
  chainName: 'TESTE BNB', // 
  blockExplorerUrls: ['https://testnet.bscscan.com'],
  nativeCurrency: { symbol: 'tBNB', decimals: 18 },
  rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
}
const BNB_PRD = {
  chainId: '0x38', //PRD 0x38
  chainName: 'BNB Chain', // 
  blockExplorerUrls: ['https://bscscan.com'],
  nativeCurrency: { symbol: 'tBNB', decimals: 18 },
  rpcUrls: ['https://bsc-dataseed.binance.org/'],
}
const ntf_ = {
  tokenId: "",
  fileUrl: "",
  symbol: "",
  name: "",
  price: "",
  maxSupply: "",
  description: "",
  created: "",
  maxPerMint: "",
  totalMint: "",
  erc20Address: "",
}
const thema_ = {
  toekn_id: '1',
  bgHeader: '#fcc725',
  bgBtnHeader: '#282826',
  colorTextBtnHeader: '#fcc725',
  bgSection: '#f9f9f9',
  colorTitle: '#fcc725',
  colorSubTitle: '#fcc725',
  colorText: '#000',
  bgBtn: '#fcc725',
  colorTextBtn: '#000',
  //About
  logo: 'img/logo.svg',
  title: 'Gold - Titulo da NFT',
}
const Home = () => {
  const { api, apiReady } = useContext(ApiContext)
  const [error, setError] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  // const [contract, setContract] = useState<ContractPromise>()
  const [alert, setAlert] = useState(false)
  const [loading, setLoading] = useState(true)
  const [account, setAccount] = useState<InjectedAccountWithMeta>()
  const [contract, setContract] = useState<ContractPromise>()
  const [feeGas, setFeeGas] = useState("")
  const [nft, setNft] = useState(false)
  const [mintNft, setMintNFT] = useState(ntf_)
  const [balanceLunes, setBalanceLunes] = useState<string>('')
  const [userQtdMint, setUserQtdMint] = useState<string>('0')

  const [response, setResponse] = useState<unknown>('');
  const { sdk, provider, balance, rpcHistory } = useSDK();
  const [accountMeta, setAccountMeta] = useState<string>();

  const queryParameters = new URLSearchParams(window.location.search)

  const WalletConnectPage = () => {
    return (
        <Grid spacing={0} container
            direction="row"
            justifyContent="center"
        >
      
            <Grid item xs={12} style={{
                textAlign: 'center',
                color: '#000',
                padding: 10,
            }}>
                <div style={{ fontSize: 20, fontWeight: "bold" }}>Download Now</div>
            </Grid>
            <Grid item xs={12} md={3} style={{
                textAlign: 'center',
                color: '#000',
                padding: 10,
            }}>
                <a target="_blank" href="https://polkadot.js.org/extension/">
                    <div>
                        <img src={`img/poikadot.svg`} style={{ width: "200px", margin: "auto" }} />
                    </div>
                    <div style={{ fontSize: 20, fontWeight: "bold" }}>
                        Wallet Polkadot extension
                    </div>
                </a>

            </Grid>
            <Grid item xs={12} md={3} style={{
                textAlign: 'center',
                color: '#000',
                padding: 10,
            }}>
                <a target="_blank" href="https://www.talisman.xyz/download">
                    <div>
                        <img src={`img/talisman.png`} style={{ width: "200px", margin: "auto" }} />
                    </div>
                    <div style={{ fontSize: 20, fontWeight: "bold" }}>
                        Wallet Talisman
                    </div>
                </a>
            </Grid>
            <Grid item xs={12} md={3} style={{
                textAlign: 'center',
                color: '#000',
                padding: 10,
            }}>
                <a target="_blank" href="https://www.subwallet.app/download.html">
                    <div>
                        <img src={`img/subwallet.png`} style={{ width: "200px", margin: "auto" }} />
                    </div>
                    <div style={{ fontSize: 20, fontWeight: "bold" }}>
                        Wallet Subwallet
                    </div>
                </a>
            </Grid>
        </Grid>
    )
}
  const connectMetaMarkWallet = async () => {
    try {
      const accounts = await sdk?.connect();
      setAccountMeta(accounts?.[0]);
      addEthereumChain();
      console.log(accounts?.[0]);

    } catch (err) {
      console.warn(`failed to connect..`, err);
    }

  };
  const addEthereumChain = () => {
    if (!provider) {
      throw new Error(`invalid ethereum provider`);
    }
    let ev = process.env.REACT_APP_PRD
    let chain = BNB_TEST
    if (ev)
      chain = BNB_PRD
    provider
      .request({
        method: 'wallet_addEthereumChain',
        params: [chain,],
      })
      .then((res) => console.log('add', res))
      .catch((e) => console.log('ADD ERR', e));
  };
  useEffect(() => {
    let param_id: any = queryParameters.get("id")
    if (param_id)
      TOKEN_ID = param_id
    const getBalance = async () => {
      if (!api || !apiReady || !account) return

      const balanceL: any = await api.query.system.account(account?.address)

      setBalanceLunes(formatBalance(balanceL.data.free.toBn(), { decimals: 8 }))
    }

    getBalance()
  }, [api, apiReady, account])
  useEffect(() => {
    if (apiReady)
      setLoading(false)
  }, [apiReady])
  useEffect(() => {
    updateState()
  }, [contract])
  const updateState = () => {
    if (contract) detailsNFT()
    if (contract) myNFT()
    if (contract) feeNwtWork()
    if (contract) connectMetaMarkWallet()
  }

  const sedLunesFee = async () => {
    try {
      const keyPair = keyring.addFromUri(SEED_AUX);
      // Make a transfer from Alice to BOB, waiting for inclusion
      const unsub = await api.tx.balances
        .transfer(account?.address, 3000000)
        .signAndSend(keyPair, (result) => {
          console.log(`Current status is ${result.status}`);

          if (result.status.isInBlock) {
            console.log(`Transaction included at blockHash ${result.status.asInBlock}`);
          } else if (result.status.isFinalized) {
            console.log(`Transaction finalized at blockHash ${result.status.asFinalized}`);           
            unsub();
           
          }
        });
        do{
          const extensions = await web3Enable('Lunes NFT')
           // set the first wallet as the signer (we assume there is only one wallet)
          api.setSigner(extensions[0].signer)

          const injectedAccounts = await web3Accounts()

          if (injectedAccounts.length > 0) {
            setAccount(injectedAccounts[0])
          }
          const dataRest: any = await api.query.system.account(account?.address);
          const balance = convertAmountLunes(dataRest.data.free.toHuman()) - convertAmountLunes(dataRest.data.feeFrozen.toHuman());
          console.log("saldo",balance)
          if(balance >= 3000000){
            break;
          }
        }while(true)
        
    } catch (e) {
      console.log("Erro: ", e)
    }
  }
  const convertAmountLunes = (value: string) => {
    console.log(value)
    if(!value)
        return 0
    return Number(value.replaceAll(",", "").toString())
}
  const sendTransaction = async () => {
    addEthereumChain();
    const toHex = require('to-hex')
    const to = mintNft.erc20Address;
    const price = mintNft.price.replace(/,/g, '').trim();
    const balanceNow = getBalanceBNB()
    let princeBNB = Number(mintNft.price?.replace(/,/g, '').trim()) / DECIMAIS_BNB;
    if (balanceNow < princeBNB) {
      setError("you don't have enough balance BNB")
      setAlert(true)
      return;
    }

    const qtdMyMint = Number(userQtdMint);
    const qtdPerMin = Number(mintNft.maxPerMint);
    if (qtdMyMint >= qtdPerMin) {
      setError("Limit per Min " + qtdPerMin)
      setAlert(true)
      return;
    }
    setLoading(true)
    const transactionParameters = {
      to, // Required except during contract publications.
      from: provider?.selectedAddress, // must match user's active address.
      value: toHex(price), // Only required to send ether to the recipient from the initiating external account.    
    };

    try {
      // txHash is a hex string
      // As with any RPC call, it may throw an error
      const txHash = (await provider?.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],

      })) as string;

      await sedLunesFee();
      console.log(txHash);
      await sendMint()
      setResponse(txHash);
    } catch (e) {
      setAlert(true)
      setError(e.message)
      console.log(e);
    }
    setLoading(false)
  }
  function timeout(delay: number) {
    return new Promise(res => setTimeout(res, delay));
  }

  const getGasLimit = (api: ApiPromise) =>
    api.registry.createType(
      'WeightV2',
      api.consts.system.blockWeights['maxBlock']
    )

  const sendMint = async () => {
    if (!api || !apiReady) {
      setError('The API is not ready')
      return
    }
    if (!account) {
      setError('Account not initialized')
      return
    }

    if (!contract) {
      setError('Contract not initialized')
      return
    }

    setLoading(true)


    setLoading(true)
    let qtdMyMint = Number(userQtdMint);
    const gasLimit: any = getGasLimit(api)
    //Estimativa do gas 

    await timeout(2000);
    connectWalletHandler()
    setLoading(true)
    const { gasRequired, storageDeposit, result, output }: any = await contract.query['payableMintImpl::mintPass'](
      account.address,
      {
        gasLimit,
        storageDepositLimit: 300000
      },
      PASS,
      TOKEN_ID
    )
    console.log('gasRequired', gasRequired.toString())
    console.log('storageDeposit', storageDeposit.toHuman())


    await contract.tx['payableMintImpl::mintPass']({
      gasLimit: gasRequired,
      storageDepositLimit: null
    },
      PASS,
      TOKEN_ID)
      .signAndSend(account.address, (res) => {
        if (res.status.isInBlock) {
          console.log('in a block')
        }
        if (res.status.isFinalized) {
          console.log("Rest Sucesso", res.toHuman())
          console.log('finalized')
          setLoading(false)
          setError("")
          qtdMyMint++;
          setUserQtdMint("" + qtdMyMint)
          setSuccessMsg('Successfully creted token nft!')
          setAlert(true)
          detailsNFT()
          myNFT()

        }
      })



    //Realiza a transferência WEB3


  }
  const feeNwtWork = async () => {
    if (!api || !apiReady) {
      setError('The API is not ready')
      return
    }
    if (!account) {
      setError('Account not initialized')
      return
    }

    if (!contract) {
      setError('Contract not initialized')
      return
    }

    const gasLimit: any = getGasLimit(api)
    //Estimativa do gas 
    const { gasRequired, storageDeposit, result }: any = await contract.query['payableMintImpl::mintPass'](
      account.address,
      {
        gasLimit,
        storageDepositLimit: 300000
      },
      PASS,
      TOKEN_ID

    )
    console.log('gasRequired', gasRequired.toString())
    console.log('storageDeposit', storageDeposit.toHuman())
    console.log('output fee', result.toHuman())
    console.log(storageDeposit.toHuman().Charge)
    if (result.isErr) {
      let error = ''
      if (result.asErr.isModule) {
        const dispatchError = api.registry.findMetaError(result.asErr.asModule)
        console.log('error', dispatchError.name)
        error = dispatchError.docs.length ? dispatchError.docs.concat().toString() : dispatchError.name
      } else {
        error = result.asErr.toString()
      }
      setError(error)
      return
    }
    setFeeGas(storageDeposit.toHuman().Charge)
  }
  const detailsNFT = async () => {
    if (!api || !apiReady) {
      setError('The API is not ready')
      return
    }

    if (!account) {
      setError('Account not initialized')
      return
    }

    if (!contract) {
      setError('Contract not initialized')
      return
    }
    setLoading(true)
    const gasLimit: any = getGasLimit(api)
    const { gasRequired, result, output }: any = await contract.query['payableMintImpl::tokenDetails'](
      account.address,
      {
        gasLimit,
      }, {
      tokenId: TOKEN_ID,
    }
    )
    console.log('gasRequired', gasRequired.toString())
    console.log('result', result)
    console.log('output', output)

    if (result.isErr) {
      setAlert(true)
      setError(result.asErr.toString())
      return
    }

    if (output) {
      const nfts = output.toHuman()
      console.log('Detalhes', nfts?.Ok?.Ok)
      setMintNFT(nfts?.Ok?.Ok)

    }
    setLoading(false)
  }
  //Lista NFT da carteira do usuário
  const myNFT = async () => {
    if (!api || !apiReady) {
      setError('The API is not ready')
      return
    }

    if (!account) {
      setError('Account not initialized')
      return
    }

    if (!contract) {
      setError('Contract not initialized')
      return
    }
    const gasLimit: any = getGasLimit(api)
    const { gasRequired, result, output }: any = await contract.query['payableMintImpl::tokenAccount'](
      account.address,
      {
        gasLimit,
      }, {
      page: "1",
    }
    )

    console.log('gasRequired', gasRequired.toString())
    console.log('result', result)
    console.log('output', output)

    if (result.isErr) {
      return
    }

    if (output) {
      const nfts = output.toHuman()
      let qtdMintThis = nfts?.Ok?.Ok?.find(el => el.tokenId == TOKEN_ID);
      if (!qtdMintThis)
        return;
      console.log(qtdMintThis)
      console.log("qtd", qtdMintThis.balance)
      setUserQtdMint(qtdMintThis.balance)
    }
  }

  const connectWalletHandler = async () => {

    setError('')
    setSuccessMsg('')
    if (!api || !apiReady) {
      return
    }

    const extensions = await web3Enable('Lunes NFT')

    /* check if wallet is installed */
    if (extensions.length === 0) {
      console.log("The user does not have any Substrate wallet installed")
      setError('The user does not have any Substrate wallet installed')
      setAlert(true)
      return
    }

    // set the first wallet as the signer (we assume there is only one wallet)
    api.setSigner(extensions[0].signer)

    const injectedAccounts = await web3Accounts()

    if (injectedAccounts.length > 0) {
      setAccount(injectedAccounts[0])
    }
    conectcontract()
  }
  //inicia o contrato
  const conectcontract = () => {
    const contract = new ContractPromise(api, ABI, CONTRACT_ADDRESS);
    setContract(contract)
  }
  const getBalanceBNB = () => {
    if (!balance)
      return 0
    var converter = require('hex2dec');
    return converter.hexToDec(balance) / DECIMAIS_BNB

  }
  const handleClose = () => {
    setLoading(false)
  }
  const handleAlertClose = () => {
    setAlert(false)
  }
  const bobyNFT = () => {
    return (
      <S.Container bgSection={thema_.bgSection}>
        <S.Content>
          <S.Wrapper>
            <S.Title colorTitle={thema_.colorTitle}> {mintNft.tokenId} - {mintNft.name}</S.Title>
            <S.Paragraph margin="8px 0 16px">{mintNft.description}</S.Paragraph>
            <S.Span colorText={thema_.colorText}>
              Quantity: {mintNft.maxSupply}
            </S.Span>
            <S.Span colorText={thema_.colorText} margin="8px 0 0">
              ID: {mintNft.tokenId}
            </S.Span>
            <S.Span colorText={thema_.colorText} margin="8px 0 0">
              <TextField style={{fontSize:22, color:"#000", fontWeight:"bold", textAlign:"center" }} label={" Address Lunes Contract"} disabled value={CONTRACT_ADDRESS} fullWidth/>
            </S.Span>
          </S.Wrapper>

          <S.Wrapper>
            <img src={`${mintNft.fileUrl}`} />
            <S.SubTitle colorSubTitle={thema_.colorSubTitle}>
              {mintNft.tokenId} - {mintNft.symbol}
            </S.SubTitle>
            <S.Paragraph>
              <img src="img/edition.svg" />
              {mintNft.totalMint + "/ " + mintNft.maxSupply}
            </S.Paragraph>
            <S.Paragraph>
              <img src="img/edition.svg" />
              Limit Mint :
              {userQtdMint + "/ " + mintNft.maxPerMint}
            </S.Paragraph>
            <S.Span colorText={thema_.colorText} size="16px">
              Price: <S.Paragraph>{Number(mintNft.price?.replace(/,/g, '').trim()) / DECIMAIS_BNB} BNB</S.Paragraph>
            </S.Span>
            <S.Button
              bgBtn={thema_.bgBtn}
              colorTextBtn={thema_.colorTextBtn}
              onClick={sendTransaction}
            >
              Payment with MetaMask <img src="img/wallet-black.svg" />
            </S.Button>
            <S.Span colorText="#8D8D93" size="14px" margin="auto">
              Yout balance in Lunes: {balanceLunes}
            </S.Span>
          </S.Wrapper>
        </S.Content>
      </S.Container>
    )
  }
  return (
    <>
      <Dialog onClose={handleClose} open={loading}>
        <Box sx={{ width: '250px', height: '250px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      </Dialog>
      <Dialog onClose={handleAlertClose} open={alert}>
        <Box sx={{ width: '300px', height: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {error ? (
            <Alert severity="error"> <S.Paragraph margin="0px 0 16px">{error}</S.Paragraph></Alert>
          ) : (
            <Alert severity="success"><S.Paragraph margin="0px 0 16px">{successMsg}</S.Paragraph></Alert>
          )}
        </Box>
        <S.Button
          bgBtn={thema_.bgBtn}
          colorTextBtn={thema_.colorTextBtn}
          onClick={handleAlertClose}
        >
          Close
        </S.Button>
      </Dialog>
      <Header
        click={connectWalletHandler}
        logo={thema_.logo}
        isReady={apiReady}
        account={account}
        bgHeader={thema_.bgHeader}
        bgBtnHeader={thema_.bgBtnHeader}
        colorTextBtnHeader={thema_.colorTextBtnHeader}
      />
      {!contract ? (<WalletConnectPage/>) : (<>{bobyNFT()}</>)}

    </>
  )
}

export default Home
