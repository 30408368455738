import device from '../../components/devices/devices'
import styled, { css } from 'styled-components'

type StyledProps = {
  bgSection: string
  colorTitle: string
  colorSubTitle: string
  colorText: string
  bgBtn: string
  colorTextBtn: string
  size: string
  margin: string
}

export const Container = styled.section<Partial<StyledProps>>`
  ${({ ...props }) => css`
    width: 100%;
    height: calc(100% - 90px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props.bgSection || '#f9f9f9'};
    ${device.tablet} {
      height: auto;
      padding: 32px 24px;
    }
  `}
`

export const Content = styled.div`
  ${({}) => css`
    width: 100%;
    max-width: 820px;
    margin: auto 0;
    padding: 48px 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    ${device.tablet} {
      padding: 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  `}
`

export const Wrapper = styled.div`
  ${({}) => css`
    width: 100%;
    max-width: 410px;
    min-height: 275px;
    max-height: 660px;
    margin-left: 24px;
    text-wrap: pretty;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    > img {
      margin-top: 105px;
      max-height: 700px;
      ${device.tablet} {
        margin-top: 24px;
      }
    }
    ${device.laptop} {
      margin-top: 24px;
    }
    ${device.tablet} {
      max-height: 100%;
      margin: 0 16px 24px;
      justify-content: center;
    }
  `}
`

export const Title = styled.h1<Partial<StyledProps>>`
  ${({ ...props }) => css`
    color: ${props.colorTitle || '#fcc725'};
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
  `}
`

export const SubTitle = styled.h3<Partial<StyledProps>>`
  ${({ ...props }) => css`
    color: ${props.colorSubTitle || '#fcc725'};
    font-size: 16px;
    font-weight: 700;
    padding: 16px 0;
  `}
`

export const Paragraph = styled.p<Partial<StyledProps>>`
  ${({ ...props }) => css`
    margin: ${props.margin};
    color: ${props.colorText || '#000'};
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: end;
    img {
      margin-right: 4px;
    }
  `}
`

export const Span = styled.span<Partial<StyledProps>>`
  ${({ ...props }) => css`
    margin: ${props.margin};
    color: ${props.colorText || '#000'};
    font-size: ${props.size || '24px'};
    font-weight: 700;
    display: flex;
    align-items: end;
    text-align: center;
    p {
      margin-left: 4px;
    }
  `}
`

export const Button = styled.button<Partial<StyledProps>>`
  ${({ ...props }) => css`
    max-width: 409px;
    width: 100%;
    height: 54px;
    margin: 24px 0 16px;
    padding: 0px 16px;
    font-size: 16px;
    font-weight: 700;
    gap: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: ${props.bgBtn || '#fcc725'};
    color: ${props.colorTextBtn || '#000'};
  `}
`
