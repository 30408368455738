import device from '../../components/devices/devices'
import styled, { css } from 'styled-components'

type StyledProps = {
  bgHeader: string
  bgBtnHeader: string
  colorTextBtnHeader: string
}

export const Header = styled.header<Partial<StyledProps>>`
  ${({ ...props }) => css`
    height: 90px;
    padding: 0 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props.bgHeader || '#fcc725'};
    position: relative;
    z-index: 10;
    ${device.laptop} {
      padding: 0 24px;
    }
  `}
`

export const Nav = styled.nav`
  ${({ ...props }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`

export const ContentLogo = styled.div`
  ${({ ...props }) => css`
    max-width: 180px;
    width: 100%;
    img {
      max-height: 60px;
      height: 100%;
    }
  `}
`

export const Button = styled.button<Partial<StyledProps>>`
  ${({ ...props }) => css`
    width: 224px;
    height: 54px;
    padding: 0px 16px;
    gap: 16px;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: ${props.bgBtnHeader || '#282826'};
    color: ${props.colorTextBtnHeader || '#fcc725'};
    ${device.tablet} {
      display: none;
    }
  `}
`
export const ButtonMobile = styled(Button)<Partial<StyledProps>>`
  ${({ ...props }) => css`
    display: none;
    ${device.tablet} {
      width: 60px;
      display: flex;
    }
  `}
`
