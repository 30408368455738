import React from 'react';
import { ApiContextProvider } from './context/ApiContext';
import Home from './containers/home';
import GlobalStyles from './styles/globals'
import { MetaMaskProvider } from '@metamask/sdk-react';

const App = () => {
	return (
		<MetaMaskProvider children debug={false} sdkOptions={{
			
			dappMetadata: {
				name: "Lunes NFT",
				url: window.location.host,
			},
			
			// Other options
		}}>
			<ApiContextProvider>
				<>
					<Home />
					<GlobalStyles />
				</>


			</ApiContextProvider>
		</MetaMaskProvider>
	);
};

export default App;
